import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { LoaderService } from './core/services/loader.service';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="content">
      <cgp-header></cgp-header>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [`
    .content {
      width: 100%;
      margin: 0 auto;
    }

  `]
})
export class AppComponent {
  themeService = inject(ThemeService);
  loaderService = inject(LoaderService);
  private router = inject(Router);


  constructor() {
    this.themeService.initByPreferred();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
      }
    })
  }
}
