<div class="container">
    <div class="title">
        <mat-icon>info_outline</mat-icon>
        <span>Attention</span>
    </div>
    <div class="content">
        <div class="note">
            <span>Dear User</span>
            <span class="description">There is an unexpected error for creating your address. Please return and try
                again. If there was still an error notify your broker’s support.</span>
        </div>
        <div class="button">
            <button (click)="goToLink(cgPayServce.retrunUrlSig())" mat-flat-button>Go Back to broker</button>
        </div>
    </div>
</div>