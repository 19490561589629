import { Component, ViewEncapsulation, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ThemeService } from 'src/app/core/services/theme.service';
import { CGPayService } from 'src/app/views/cgpay/service/cg-pay.service';
import { AsyncPipe } from "@angular/common";

@Component({
    selector: 'cgp-header',
    imports: [MatIconModule, RouterModule, AsyncPipe],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  themeService = inject(ThemeService);


  cgPayServce = inject(CGPayService);

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
