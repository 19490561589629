import { Component, ViewEncapsulation, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAddressQrCodeData } from '../model/address-qr-code-data';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CGPayService } from 'src/app/views/cgpay/service/cg-pay.service';

@Component({
  selector: 'address-qr-code',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './attention.component.html',
  styleUrl: './attention.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AttentionComponent {
  data = inject<IAddressQrCodeData>(MAT_DIALOG_DATA);
  dialogRef = inject<MatDialogRef<AttentionComponent>>(MatDialogRef);


  cgPayServce = inject(CGPayService);

  close(): void {
    this.dialogRef.close();
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
